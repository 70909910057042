import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import { Heading, Container, Section, Columns } from "react-bulma-components";

const About = () => {
  return (
    <>
      <SEO title="Om Wallang | Accessoarer och smarta prylar online" />
      <Container>
        <Section className="info-section">
          <Heading className="has-text-centered">Om Wallang</Heading>
          <br />
          <p>
            Wallang bildades under våren 2019. Vår huvudsakliga produkt är armband men vi har även skärp och utvalda smarta prylar som vi själva använder, tycker om och vill
            rekommendera till andra.
          </p>
          <p>
            Våra armband är trendriktiga och noga utvalda. Vi hoppas att ni tycker om dem lika mycket som vi. Har du några frågor, funderingar eller åsikter om vår webbshop är du
            varmt välkommen att kontakta oss på <a href="mailto:info@wallang.se">info@wallang.se</a> eller våran sida på{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/wallang.se">
              Facebook
            </a>
            .
          </p>
          <br />
          <Columns className="is-centered product-page">
            <Columns.Column>
              <h3>
                <strong>Adress</strong>
              </h3>
              <p>
                Wallang HB <br />
                C/o Box 934 <br />
                Östersundsgatan 7 <br />
                162 74 VÄLLINGBY
              </p>
            </Columns.Column>
            <Columns.Column>
              <h3>
                <strong>Organisationsnummer</strong>
              </h3>
              <p>969789-9301</p>
            </Columns.Column>
          </Columns>

          <div>
            <StaticImage style={{ borderRadius: "3px" }} alt="Wallang logo" src="../images/banner-silhouette.jpg" placeholder="none" />
          </div>
        </Section>
      </Container>
    </>
  );
};

export default About;
